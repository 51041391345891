import { Box, Dialog, styled, Typography } from "@mui/material";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import HardBreak from "@tiptap/extension-hard-break";
import { EditorContent, useEditor } from "@tiptap/react";
import InexButton from "../../../InexButton";
import React from "react";
import { useCreateQuestionMutation } from "../../../../store/api/odyssee";
import useOdyssee from "../../../../context/odyssee/useOdyssee";
import { useAuthToken } from "../../../../hooks/auth";

const extensions = [
	StarterKit.configure({
		hardBreak: false,
	}),
	Placeholder.configure({
		placeholder: "Votre question ...",
	}),
	HardBreak.extend({
		addKeyboardShortcuts() {
			return {
				Enter: () => this.editor.commands.setHardBreak(),
			};
		},
	}),
];

const InputContainer = styled(Box)(() => {
	return {
		backgroundColor: "rgba(255,255,254,0.16)",
		borderRadius: 10,
		padding: 16,
	};
});
const EditorStyledContent = styled(EditorContent)(() => {
	return {
		color: "#FFFFFF",
		fontFamily: "Branding",
		fontWeight: 600,
		maxHeight: "50vh",
		overflowY: "auto",
	};
});

const CreateQuestion = ({ isOpen, handleModalClose, refetchUserQuestions }) => {
	const [createQuestion] = useCreateQuestionMutation();
	const { odyssee } = useOdyssee();
	const authToken = useAuthToken();

	const onContentUpdated = ({ editor }) => {
		// setDescription(editor.getHTML());
	};

	const editor = useEditor({
		extensions,
		content: "",
		onUpdate: onContentUpdated,
	});

	const dismiss = () => {
		handleModalClose();
		editor.commands.setContent("");
	};

	const uploadQuestion = () => {
		createQuestion({ authToken, question: editor?.getHTML(), idodyssee: odyssee?.id }).then(() => {
			refetchUserQuestions();
		});
		dismiss();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleModalClose}
			maxWidth={"md"}
			fullWidth={true}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "0 0 30px 5px rgb(255, 255, 255, 0.6)",
					borderRadius: 10,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundColor: "rgba(0, 0, 0, 0.9)",
					},
				},
			}}
		>
			<Box sx={{ backgroundColor: "#18122D", padding: 3 }}>
				<Box sx={{ display: "flex", width: "100%" }} mb={3}>
					<Typography
						color={"#ffffff"}
						fontFamily={"Branding Bold"}
						fontWeight={700}
						textAlign={"center"}
						fontSize={30}
					>
						Poser une nouvelle question à la communauté
					</Typography>
				</Box>
				<InputContainer mb={3}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
						mb={2}
					>
						<Typography color={"#FFFFFE4D"}>Votre question</Typography>
						<Typography color={"#FFFFFE4D"}>Requis</Typography>
					</Box>
					<EditorStyledContent style={{ width: "100%", minHeight: "150px" }} editor={editor} />
				</InputContainer>
				<Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
					<InexButton
						onClick={dismiss}
						variant={"text"}
						textWithGradient={false}
						textSx={{
							color: "#182B43",
						}}
						sx={{
							backgroundColor: "#C7C4DE",
							width: 100,
						}}
						text={"Annuler"}
					/>
					<InexButton
						onClick={uploadQuestion}
						variant={"text"}
						textWithGradient={false}
						textSx={{
							color: "#ffffff",
						}}
						sx={{
							backgroundColor: "#EF7D8F",
							width: 260,
						}}
						text={"Envoyer ma question"}
					/>
				</Box>
			</Box>
		</Dialog>
	);
};

export default CreateQuestion;
