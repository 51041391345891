import { api } from "./rtkApi";
import { store } from "../store";
import { encode } from "querystring";

export const odysseeApi = api.injectEndpoints({
	endpoints: (build) => ({
		getOdysseeUserList: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;

				const ts = Date.now();

				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/list?ts=${ts}`,
				};
			},
		}),
		getOdyssee: build.query({
			query: ({ idOdyssee, authToken }) => {
				const ts = Date.now();
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/${idOdyssee}?ts=${ts}`,
				};
			},
		}),
		getOdysseeModules: build.query({
			query: ({ idOdyssee, authToken }) => {
				const ts = Date.now();
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/modules/${idOdyssee}?ts=${ts}`,
				};
			},
		}),
		getOdysseeRessources: build.query({
			query: ({ stepId, authToken }) => {
				const ts = Date.now();
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/ressources/${stepId}?ts=${ts}`,
				};
			},
		}),
		updateOdysseeStep: build.mutation({
			query: ({ odysseeUserId, authToken, etapeId }) => {
				return {
					method: "POST",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/updateOdysseeStep/${odysseeUserId}`,
					body: encode({ etapeId }),
				};
			},
		}),
		getOdysseeWebRessource: build.query({
			query: ({ odysseeId, authToken, categorie, ressourceId }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/webContent/${odysseeId}?categorie=${categorie}&ressourceId=${ressourceId}`,
				};
			},
		}),
		completeOdyssee: build.mutation({
			query: ({ odysseeUserId, authToken }) => {
				return {
					method: "PATCH",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/complete/${odysseeUserId}`,
				};
			},
		}),
		rateOdyssee: build.mutation({
			query: ({ odysseeUserId, authToken, note, remarque }) => {
				return {
					method: "PATCH",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/rate/${odysseeUserId}`,
					body: encode({ note, remarque }),
				};
			},
		}),
		getOdysseeGoFurther: build.query({
			query: ({ odysseeId, authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/get-odyssee-plus-loins/${odysseeId}`,
				};
			},
		}),
		getAllOdyssee: build.query({
			query: ({ authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/all`,
				};
			},
		}),
		getAllOthersOdyssee: build.query({
			query: ({ authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/others`,
				};
			},
		}),
		createQuestion: build.mutation({
			query: ({ authToken, question, idodyssee }) => {
				return {
					method: "POST",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/question`,
					body: encode({ question, idodyssee }),
				};
			},
		}),
		getUserQuestions: build.query({
			query: ({ odysseeId, authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/question/user/${odysseeId}`,
				};
			},
		}),
		getAllQuestions: build.query({
			query: ({ odysseeId, authToken, page }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/question/all/${odysseeId}?page=${page}`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetOdysseeUserListQuery,
	useGetOdysseeQuery,
	useGetOdysseeModulesQuery,
	useGetOdysseeRessourcesQuery,
	useUpdateOdysseeStepMutation,
	useGetOdysseeWebRessourceQuery,
	useLazyGetOdysseeWebRessourceQuery,
	useCompleteOdysseeMutation,
	useRateOdysseeMutation,
	useGetOdysseeGoFurtherQuery,
	useGetAllOdysseeQuery,
	useGetAllOthersOdysseeQuery,
	useCreateQuestionMutation,
	useGetUserQuestionsQuery,
	useGetAllQuestionsQuery,
} = odysseeApi;
