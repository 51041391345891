import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InexButton from "../../../InexButton";
import CommunityCard from "./CommunityCard";
import CreateQuestion from "./CreateQuestion";
import { useGetUserQuestionsQuery } from "../../../../store/api/odyssee";
import { useAuthToken } from "../../../../hooks/auth";
import useOdyssee from "../../../../context/odyssee/useOdyssee";

const InexAccordion = styled(Accordion)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		borderRadius: "20px",
		padding: "5px",
		borderTopLeftRadius: "20px !important",
		borderTopRightRadius: "20px !important",
		borderBottomRightRadius: "20px !important",
		borderBottomLeftRadius: "20px !important",
		maxWidth: "90vw",
		width: "100%",
		minWidth: "500px",
		"..MuiAccordion-root": {},
	};
});
const MyQuestions = () => {
	const [isExpanded, setExpanded] = React.useState(true);
	const [isModalOpen, setModelOpen] = React.useState(false);
	const authToken = useAuthToken();
	const { odyssee } = useOdyssee();

	const { data: userQuestions, refetch: refetchUserQuestions } = useGetUserQuestionsQuery(
		{ authToken, odysseeId: odyssee?.id },
		{ skip: !odyssee?.id || !authToken }
	);

	const createNewQuestion = (event) => {
		event?.stopPropagation();
		setModelOpen(true);
	};
	console.log(userQuestions);

	return (
		<React.Fragment>
			<InexAccordion onChange={(event, expanded) => setExpanded(expanded)} defaultExpanded={true}>
				<AccordionSummary aria-controls="panel1-content" id="panel1-header" sx={{ width: "100%", margin: 0 }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Typography color={"#ffffff"} fontFamily={"Branding"} fontWeight={"bold"} fontSize={24}>
							Mes questions
						</Typography>
						<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
							<InexButton
								onClick={createNewQuestion}
								variant={"text"}
								textWithGradient={false}
								textSx={{
									color: "#ffffff",
								}}
								sx={{
									backgroundColor: "#EF7D8F",
									width: 260,
								}}
								text={"Poser une nouvelle question"}
							/>
							<ExpandMoreIcon
								sx={{
									color: "white",
									transform: `rotate(${isExpanded ? 180 : 0}deg)`,
									transition: "transform 0.2s ease",
								}}
							/>
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						{userQuestions?.map((question) => (
							<CommunityCard
								key={question?.id}
								firstName={question?.prenom}
								lastName={question?.nom}
								date={question?.dateCreate}
								question={question?.question}
								answerDate={question?.dateAnswer}
								answerContent={question?.answer}
								audio={question?.audio}
								answerUser={question?.dataAuteur}
							/>
						))}
					</Box>
				</AccordionDetails>
			</InexAccordion>

			<CreateQuestion
				handleModalClose={() => setModelOpen(false)}
				isOpen={isModalOpen}
				refetchUserQuestions={refetchUserQuestions}
			/>
		</React.Fragment>
	);
};

export default MyQuestions;
