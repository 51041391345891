import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleTag = () => {
  const location = useLocation(); // Récupère le chemin actuel

  useEffect(() => {
    // Charge le script gtag.js une seule fois au montage
    if (!window.gtag) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-1049373766';
      document.body.appendChild(script);

      const gtagScript = document.createElement('script');
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        // Configuration pour les deux IDs
        gtag('config', 'AW-1049373766');
        gtag('config', 'GTM-MMG6LDXV');
        gtag('config', 'GT-552K7JJ');
      `;
      document.body.appendChild(gtagScript);
    }
  }, []);

  useEffect(() => {
    // Envoie un événement à chaque changement de page
    if (window.gtag) {
      const page_path = location.pathname + location.search
      window.gtag('config', 'AW-1049373766', { page_path });
      window.gtag('config', 'GTM-MMG6LDXV', { page_path });
      window.gtag('config', 'GT-552K7JJ', { page_path });

      // Envoi de l'event personnalisé "page_view"
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
};

export default GoogleTag;
