import { Box, PaginationItem, Skeleton, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../../../hooks/responsive";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { motion } from "motion/react";
import MyQuestions from "./MyQuestions";
import { useGetAllQuestionsQuery } from "../../../../store/api/odyssee";
import { useAuthToken } from "../../../../hooks/auth";
import useOdyssee from "../../../../context/odyssee/useOdyssee";
import CommunityCard from "./CommunityCard";
import Pagination from "@mui/material/Pagination";
import { AnimatePresence } from "motion/react";

const StyledPagination = styled(Pagination)(({ theme }) => ({
	display: "flex",
	gap: 15,
	alignItems: "center",
}));

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
	color: "#ffffff",
	borderColor: "#ffffff",
	borderRadius: "4px",
	fontFamily: "Branding",
	fontWeight: 600,
	margin: "0 5px",
	"&.Mui-selected": {
		backgroundColor: "#ffffff",
		color: "#182B43",
		"&:hover": {
			backgroundColor: "#ffffff",
			color: "#182B43",
		},
	},
	"&:hover": {
		backgroundColor: "rgba(255,255,255,0.64)",
		color: "#182B43",
	},
}));

const Community = ({ goFurther }) => {
	const authToken = useAuthToken();
	const { odyssee } = useOdyssee();

	const [page, setPage] = React.useState(1);
	const handleChange = (event, value) => {
		window.scrollTo(0, 0);
		setPage(value);
	};

	const { data: questionsData, isFetching } = useGetAllQuestionsQuery(
		{ authToken, odysseeId: odyssee?.id, page: page - 1 },
		{ skip: !odyssee?.id || !authToken }
	);

	return (
		<motion.div
			transition={{ type: "spring", duration: 1, delay: 0 }}
			initial={{ opacity: 0, scale: 0.95 }}
			animate={{ opacity: 1, scale: 1 }}
		>
			<Box sx={{ fontFamily: "Branding", mt: 8, mb: 8, width: "90vw" }}>
				{page === 1 && (
					<motion.div
						transition={{ type: "spring", duration: 1, delay: 0 }}
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
					>
						<MyQuestions />
					</motion.div>
				)}
				{questionsData?.questions?.length > 0 && (
					<Typography
						color={"#ffffff"}
						fontFamily={"Branding"}
						fontWeight={"bold"}
						fontSize={24}
						mb={3}
						mt={3}
					>
						Toutes les questions de la communauté
					</Typography>
				)}
				<AnimatePresence>
					{!isFetching && (
						<motion.div
							transition={{ type: "spring", duration: 1 }}
							initial={{ opacity: 0, scale: 1 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0 }}
						>
							<Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginBottom: 3 }}>
								{questionsData?.questions?.map((question) => (
									<CommunityCard
										key={question?.id}
										firstName={question?.prenom}
										lastName={question?.nom}
										date={question?.dateCreate}
										question={question?.question}
										answerDate={question?.dateAnswer}
										answerContent={question?.answer}
										audio={question?.audio}
										answerUser={question?.dataAuteur}
									/>
								))}
							</Box>
						</motion.div>
					)}
				</AnimatePresence>
				{questionsData?.nbPages && (
					<Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
						<StyledPagination
							hideNextButton={true}
							hidePrevButton={true}
							count={questionsData?.nbPages}
							variant="outlined"
							shape="rounded"
							page={page}
							onChange={handleChange}
							renderItem={(item) => <StyledPaginationItem {...item} />}
						/>
					</Box>
				)}
			</Box>
		</motion.div>
	);
};

export default Community;
