import React from "react";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import moment from "moment/moment";
import WavesurferPlayer from "@wavesurfer/react";
import { ReactComponent as Play } from "../../../../resources/images/svg/odyssee/play-podcast.svg";
import { ReactComponent as Pause } from "../../../../resources/images/svg/odyssee/pause-podcast.svg";

const Container = styled(Box)(({ theme }) => ({
	background: "rgba(255,255,255,0.1)",
	borderRadius: 20,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
	background: "rgba(255,255,255,0.1)",
	borderBottomLeftRadius: 20,
	borderBottomRightRadius: 20,
	padding: "20px 30px",
}));

const UserContainer = styled(Box)(({ theme }) => ({
	background: "#A5D0C9",
	height: 48,
	width: 48,
	borderRadius: "50%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

const Divider = styled(Box)(({ theme }) => ({
	width: "100%",
	height: 1,
	background: "#FFFFFF33",
	margin: "20px 0",
}));

const AuthorContainer = styled(Box)(({ theme }) => ({
	position: "absolute",
	top: 0,
	left: -35,
	background: "#C7C4DE",
	borderRadius: "40px",
	padding: "4px 8px",
}));

const ImageContainer = styled(Box)(({ theme }) => ({
	width: 115,
	height: 115,
	borderRadius: "50%",
	background: "rgba(255,255,255,0.1)",
	display: "flex",
	justifyContent: "center",
	overflow: "hidden",
}));

const ButtonContainer = styled(Box)(() => {
	return {
		backgroundColor: "rgba(255,255,255,0.4)",
		width: 44,
		height: 44,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 22,
		cursor: "pointer",
	};
});

const AudioCardPlay = ({ audio }) => {
	const [wavesurfer, setWavesurfer] = React.useState(null);
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [duration, setDuration] = React.useState(null);

	const onReady = (ws) => {
		setWavesurfer(ws);
		const d = moment.duration(ws.getDuration(), "seconds");
		setDuration(d.minutes() + ":" + d.seconds());
		setIsPlaying(false);
	};

	const onPlayPause = () => {
		wavesurfer && wavesurfer.playPause();
	};

	return (
		<Box
			sx={{
				background: "#FFFFFF33",
				padding: "20px 15px",
				borderRadius: "20px",
				display: "flex",
				gap: 2,
				alignItems: "center",
				width: "fit-content",
			}}
		>
			<ButtonContainer onClick={onPlayPause}>
				{!isPlaying ? <Play style={{ width: 30, height: 30 }} /> : <Pause style={{ width: 30, height: 30 }} />}
			</ButtonContainer>
			<Typography color={"#ffffff"} fontFamily={"Branding"} fontWeight={600} fontSize={16}>
				{duration}
			</Typography>
			<WavesurferPlayer
				height={40}
				waveColor={"rgb(185,185,185)"}
				progressColor={"#ffffff"}
				url={audio}
				width={300}
				onReady={onReady}
				onPlay={() => setIsPlaying(true)}
				onPause={() => setIsPlaying(false)}
				barWidth={3}
				barGap={4}
				barRadius={20}
			/>
		</Box>
	);
};

const CommunityCard = ({ firstName, lastName, date, question, answerDate, answerContent, answerUser, audio }) => {
	const formatedDate = React.useMemo(() => {
		return moment(date * 1000).format("DD MMMM YYYY");
	}, [date]);

	const formatedDateAnswer = React.useMemo(() => {
		return moment(answerDate * 1000).format("DD MMMM YYYY");
	}, [answerDate]);

	const author = React.useMemo(() => {
		return JSON.parse(answerUser || "{}");
	}, []);

	const hasAnswer = React.useMemo(() => {
		return !!formatedDateAnswer && !!author?.nom && (!!answerContent || !!audio);
	}, [formatedDateAnswer, answerContent]);

	return (
		<Container>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 20px" }}>
				<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
					<UserContainer>
						<Typography color={"#182B43"} fontFamily={"Yeseva One"} fontSize={20}>
							{firstName[0]}
						</Typography>
					</UserContainer>
					<Typography
						color={"#FFFFFF"}
						fontFamily={"Branding"}
						fontWeight={600}
						fontSizze={18}
					>{`${firstName} ${lastName[0]}`}</Typography>
				</Box>
				<Typography color={"rgba(255,255,255,0.5)"} fontFamily={"Branding"} fontWeight={600} fontSize={16}>
					Question posée le {formatedDate}
				</Typography>
			</Box>
			<ContentContainer>
				<Typography
					color={"#ffffff"}
					fontFamily={"Branding"}
					fontWeight={600}
					fontSize={16}
					dangerouslySetInnerHTML={{ __html: question }}
				/>
				{!!hasAnswer && (
					<React.Fragment>
						<Divider />
						<Grid container spacing={4}>
							<Grid item xs={2}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Box
										sx={{ width: 115, height: 115, position: "relative", justifyContent: "center" }}
									>
										<AuthorContainer>
											<Typography color={"#182B43"} fontFamily={"Branding"} fontWeight={600}>
												Auteur
											</Typography>
										</AuthorContainer>
										<ImageContainer>
											<img
												src={author?.image}
												alt={author?.prenom}
												style={{ height: "100%" }}
											/>
										</ImageContainer>
									</Box>
									<Box sx={{ marginTop: 2, justifyContent: "center" }}>
										<Typography
											color={"#ffffff"}
											fontFamily={"Branding"}
											fontWeight={600}
											fontSize={16}
											textAlign="center"
										>
											{`${author?.prenom} ${author?.nom}`}
										</Typography>
										<Typography
											color={"rgba(251,240,229,0.5)"}
											fontFamily={"Branding"}
											fontWeight={600}
											fontSize={13}
											textAlign="center"
										>
											{author?.profession}
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={10}>
								<Box>
									<Typography
										color={"rgba(255,255,255,0.5)"}
										fontFamily={"Branding"}
										fontWeight={600}
										fontSize={16}
										mb={3}
									>
										À répondu le {formatedDateAnswer}
									</Typography>
									{!!audio ? (
										<AudioCardPlay audio={audio} />
									) : (
										<Typography
											color={"#ffffff"}
											fontFamily={"Branding"}
											fontWeight={600}
											fontSize={16}
											dangerouslySetInnerHTML={{ __html: answerContent }}
										/>
									)}
								</Box>
							</Grid>
						</Grid>
					</React.Fragment>
				)}
			</ContentContainer>
		</Container>
	);
};

export default CommunityCard;
